import React from "react"
import styled from "styled-components"
import api from "../api"
import { useState, useContext } from "react"
import { CheckoutContext } from "../../Contexts/CheckoutContext"
import Close from "../../icons/close-icon-popup.svg"

const Div = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgb(34, 34, 34, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  /* Close button icon CSS */
  .close-button {
    height: 12px;
    width: 12px;
    position: absolute;
    top: 25px;
    right: 45px;
    cursor: pointer;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  width: 520px;
  height: 376px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;

  .bold {
    font-weight: bold;
  }

  @media (max-width: 560px) {
    width: 90%;
  }
`

const Container1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  width: 520px;
  height: 320px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;

  .bold {
    font-weight: bold;
  }
  @media (max-width: 560px) {
    width: 90%;
  }
`
const Form = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
`
const Input = styled.input`
  width: 50px;
  align-self: center;
  text-align: center;
`

const Text = styled.span`
  width: 80%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #111111;
  flex: none;
  flex-grow: 0;
  margin: 24px 0px;
  margin-bottom: 0px;
  @media (max-width: 560px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const Text1 = styled.span`
  width: 80%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #111111;
  margin: 0px;
  @media (max-width: 560px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const Title = styled.span`
  width: 80%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: #111111;
  flex: none;
  flex-grow: 0;
  margin: 24px 0px;
  @media (max-width: 560px) {
    width: 70%;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 0px;
  }
`
const Title1 = styled.span`
  width: 80%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: #111111;
  margin: 48px 0px;
  @media (max-width: 560px) {
    width: 70%;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }
`

const Label = styled.label`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #111111;
  @media (max-width: 560px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 160px;
  height: 42px;
  background: rgb(0, 202, 66);
  border-radius: 60px;
  border: 2px solid rgb(0, 202, 66);
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: none;
  margin: 12px 0px;
  /* text CSS */
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: white;
  flex: none;
  flex-grow: 0;
  :hover {
    background-color: rgb(50, 205, 50);
  }
`

function UpgradePopup({ hide }) {
  //state that manages number of licenses user wants
  const [add, setAdd] = useState(1)
  //is button disabled state
  const [isDisabled, setIsDisabled] = useState(false)
  //success msg
  const [success, setSuccess] = useState(false)
  //checkout context
  const {
    activeLicenses,
    setActiveLicenses,
    subscriptionID,
    number,
    setNumber,
  } = useContext(CheckoutContext)
  return (
    <Div>
      {!success && (
        <Container
          onClick={e => e.stopPropagation()}
          className="popup-container"
        >
          <Close className="close-button" onClick={hide} />
          <Title>Increase number of licenses</Title>
          <Text className="current-number">
            Current number of licenses:
            <Text className="bold"> {activeLicenses}</Text>
          </Text>
          <Form className="form">
            <Label htmlFor="increase">Number you want to add:</Label>
            <Input
              type="number"
              id="increase"
              name="increase"
              value={add}
              min={1}
              onChange={e => {
                setAdd(e.target.value)
              }}
            />
          </Form>
          <Button
            disabled={isDisabled}
            className="green"
            onClick={() => {
              if (isDisabled === false) {
                setIsDisabled(true)
                api
                  .post(`/api/v1/fastspring/subscriptions/`, {
                    subscriptions: [
                      {
                        subscription: subscriptionID,
                        quantity: parseInt(add) + parseInt(activeLicenses),
                      },
                    ],
                  })
                  .then(res => {
                    // console.log(res)
                    setActiveLicenses(parseInt(add) + parseInt(activeLicenses))
                    setNumber(number + 1)
                    setSuccess(true)
                    // setTimeout(() => {
                    //   hide()
                    //   setSuccess(false)
                    // }, 15000)
                    setIsDisabled(false)
                  })
                  .catch(err => {
                    console.log(err)
                    setIsDisabled(false)
                  })
              }
            }}
          >
            Confirm
          </Button>
        </Container>
      )}
      {success && (
        <Container1>
          <Close className="close-button" onClick={hide} />
          <Title1>You have successfully increased number of users!</Title1>
          <Text1>
            Previous number of licenses:{" "}
            <strong>{parseInt(activeLicenses) - parseInt(add)}</strong>
          </Text1>
          <Text1>
            Current number of licenses: <strong>{activeLicenses}</strong>
          </Text1>
        </Container1>
      )}
    </Div>
  )
}

export default UpgradePopup
