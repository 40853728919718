import React from "react"
import styled from "styled-components"

const UpgradePlan = styled.section`
  .upgradePlan {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    width: max-content;
    height: 42px;
    background: #00ca42;
    border-radius: 60px;
    border: none;
    flex: none;
    flex-grow: 0;
    margin: 0px 16px;
    cursor: pointer;
    overflow-x: hidden;
    overflow-y: hidden;
    @media (max-width: 900px) {
      width: 100%;
      margin: 0px;
    }

    &:hover {
      background: rgba(0, 202, 66, 0.6);
    }

    .text {
      width: max-content;
      height: 18px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      flex: none;
      flex-grow: 0;
      margin: 0px 10px;
      @media (max-width: 600px) {
        width: 80%;
        margin: 0px;
        padding: 0px;
        height: max-content;
      }
    }
  }

  .upgradePlanPlus {
    @media (max-width: 768px) {
      .text {
        text-align: center;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: #ffffff;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }
  }
`

const UpgradeOrganisation = styled.section`
  .upgradeOrganisation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    width: max-content;
    height: 42px;
    border-radius: 60px;
    flex: none;
    flex-grow: 0;
    margin: 0px 16px;
    border: 2px solid #007de6;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background: rgba(0, 125, 230, 0.5);
      border: 2px solid rgba(0, 125, 230, 0.5);

      .text {
        color: #ffffff;
      }
    }

    .text {
      width: max-content;
      height: 18px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      color: #007de6;
      flex: none;
      flex-grow: 0;
      margin: 0px 10px;

      &:hover {
        color: #ffffff;
      }
    }
  }
`

const DisabledButton = styled.section`
  .disabledButton {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 24px;
    width: max-content;
    height: 42px;
    background: #dddddd;
    border-radius: 60px;
    flex: none;
    flex-grow: 0;
    margin: 0px 16px;
    border: #dddddd;
    margin-left: -5px;

    .text {
      width: max-content;
      height: 18px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      flex: none;
      flex-grow: 0;
    }
  }
`

function UpgradeButton({ name, action, className, disabled }) {
  return (
    <div>
      <DisabledButton>
        <UpgradeOrganisation>
          <UpgradePlan>
            <button disabled={disabled} className={className} onClick={action}>
              <span className="text">{name}</span>
            </button>
          </UpgradePlan>
        </UpgradeOrganisation>
      </DisabledButton>
    </div>
  )
}

UpgradeButton.defaultProps = {
  name: "Current",
  className: "green",
  disabled: false,
}

export default UpgradeButton
